import {
  Chart,
  LineSeries,
  Category,
  Tooltip,
  Legend,
  DataLabel,
} from "@syncfusion/ej2-charts";
import { registerLicense } from "@syncfusion/ej2-base";
import { Data } from "@syncfusion/ej2-charts/src/common/model/data";

Chart.Inject(LineSeries, Category, Tooltip, Legend, DataLabel);

class BurndownChart {
  constructor({ wrapper, data, title = "Gráfico de Burndown", data_inicial, data2 }) {
    registerLicense(
      "ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5Sdk1iWXtbcndcQmNc"
    );
    console.log("Licença Syncfusion registrada com sucesso.");

    try {
      if (!wrapper) {
        throw new Error("É necessário fornecer um wrapper para inicializar o Gráfico.");
      }
      // test
      this.$wrapper = $(wrapper);

      console.log("data", data)
      console.log("data2", data2)

      let ProgressoInicial = 100
      const serieIdeal = data.map((data) => {
        ProgressoInicial -= data.Esforco
        return {
          Data: data.Data,
          Ideal: ProgressoInicial
        }
      })

console.log("serieIdeal", serieIdeal)


let ProgressoInicialRealizado = 100
const serieRealizado = data2.map((data) => {
  ProgressoInicialRealizado -= data.Esforco
  return {
    Data: data.Data,
    Realizado: ProgressoInicialRealizado
  }
})
console.log("serieRealizada", serieRealizado)

console.log("daqui pra cima ta ok temos os datas e os progresso de ideal e realizado")


const bigdata = [...serieIdeal, ...serieRealizado]
console.log("bigdata", bigdata)

const sortedData = bigdata.sort((a, b) => new Date(a.Data) - new Date(b.Data));

sortedData.unshift({ Data: data_inicial, Ideal: 100, Realizado: 100 });

console.log("sortedData big", sortedData)


const consolidatedData = sortedData.reduce((acc, current) => {
  const existing = acc.find(item => item.Data === current.Data);

  if (existing) {
    existing.Ideal = current.Ideal !== undefined ? current.Ideal : existing.Ideal;
    existing.Realizado = current.Realizado !== undefined ? current.Realizado : existing.Realizado;
  } else {
    acc.push({ ...current });
  }

  return acc;
}, []);

console.log(consolidatedData);


const processedData = consolidatedData.map((item, index, arr) => {
  const current = { ...item };

  if (current.Ideal === undefined && index > 0) {
    current.Ideal = arr[index - 1].Ideal;
  }

  if (current.Realizado === undefined && index > 0) {
    current.Realizado = arr[index - 1].Realizado;
  }

  return current;
});

console.log("dado formatado",processedData);
      // Criando o gráfico de Burndown
      const lineGrid = new Chart({
        primaryXAxis: {
          title: "Data de Entrega",
          valueType: "Category",
          interval: 1,
          labelPlacement: "OnTicks",
          
        },
        primaryYAxis: {
          title: "Progresso (%)",
          minimum: 0,
          maximum: 100,
          interval: 20,
        },
        series: [
          {
            dataSource: processedData,
            xName: "Data",
            yName: "Ideal",
            name: "Ideal",
            type: "Line",
            marker: { visible: true },
          },{
            dataSource: processedData,
            xName: "Data",
            yName: "Realizado",
            name: "Realizado",
            type: "Line",
            marker: { visible: true },
          },
        ],
        title,
        tooltip: { enable: true },
        legendSettings: { visible: true },
      });

      // Renderizando o gráfico no elemento
      lineGrid.appendTo(this.$wrapper[0]);
      console.log("Gráfico de Burndown renderizado com sucesso.");
    } catch (e) {
      console.error("Erro ao inicializar o Gráfico de Burndown:", e);
    }
  }
}

frappe.provide("frappe.burndown");
frappe.burndown.BurndownChart = BurndownChart;

export default BurndownChart;
